import { useEffect } from 'react'
import { Box, Grid } from '@mui/material'

import logo from 'Assets/images/logo.png'
import mobile from 'Assets/images/mobile.svg'
import LoginComponent from '../Components/LoginComponent'
import './login.css'

function LoginPage() {
    useEffect(() => {
        localStorage.removeItem('token')
    }, [])

    return (
        <>
            <Box style={styles.container} sx={{ width: '100%' }}>
                <Grid container style={styles.container}>
                    <Grid item xs={12} md={4}>
                        <div className="h-screen bg-main flex justify-center items-center">
                            <div className='login-left space-y'>
                                <img className="login-logo" src={logo} alt="UCS Logo"/>
                                <div className="text-slate-blue text-center">
                                    <div className="login-header">
                                        NSW Health UCS Module
                                    </div>
                                    <div className="login-subheader">
                                        NSW Health Urgent Care Services
                                    </div>
                                    <p>
                                        NSW Health UCS Module enables clinics to collect additional data elements
                                        that are not typically recorded in practice software. The collection of
                                        this information is essential to supporting the monitoring and evaluation
                                        of the NSW Health UCS Initiative
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid style={styles.right_col} item xs={12} md={8}>
                        <LoginComponent />
                        <hr style={styles.horizontalLine} />
                        <p style={styles.bottom_details}>
                            Don't have an account? Unable to connect?
                        </p>
                        <a
                            href='https://www.pencs.com.au/support/'
                            style={styles.bottom_details_line2}
                        >
                            Please contact Pen CS Support
                        </a>
                        <div style={styles.bottom_details}>
                            <img style={styles.mobile_img} src={mobile} alt='mobile' />
                            <a href='tel:1800762993' style={styles.support_number}>
                                Support 1800 762 993
                            </a>
                        </div>
                        <p style={styles.version}>
                            v1.0.120
                        </p>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
const styles = {
    container: { height: '100vh', margin: '0px', padding: '0px' },
    left_col: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F3F6CC',
        color: '#ffffff',
        justifyContent: 'center',
        padding: '0px 49px 0px 75px',
    },
    right_col: {
        backgroundColor: '#ffffff',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title_container: {
        gap: '16px',
        display: 'flex',
        flexDirection: 'column',
    },
    header_h1: {
        margin: '0px',
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
    },
    header_h6: {
        margin: '0px',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
    },
    header_p: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        width: '368px',
    },
    title_img: {
        marginBottom: '48px',
    },
    forgot_password: {
        cursor: 'pointer',
        margin: '0px',
    },

    horizontalLine: {
        color: '#00000030',
        height: '0px',
        width: '496.001px',
        marginTop: '27px',
        marginBottom: '23px',
    },
    bottom_details: {
        display: 'flex',
        width: '495px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '18px',
        marginBottom: '8px',
        fontSize: '16px',
    },
    version: {
        display: 'flex',
        width: '495px',
        color: '#00000060',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '18px',
        marginBottom: '8px',
        fontSize: '12px',
    },
    bottom_details_line2: {
        color: '#000000',
        display: 'flex',
        width: '495px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '18px',
        margin: '0px',
        fontSize: '14px',
        marginBottom: '18px',
    },
    support_number: {
        textDecoration: 'none',
        width: '201.733px',
        color: '#1A485E',
        fontSize: '19.4px',
        fontWeight: '500',
        margin: '0px',
    },
}

export default LoginPage
